import * as React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import { Trans } from '@lingui/macro';
import Layout from '../components/Layout';
import Container from '../components/Container';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Link from '../components/Link';
import { getBlogPage } from '../utils/url';

const ContentWrapper = tw.div`min-w-0 w-full flex-auto lg:static lg:max-h-full lg:overflow-visible mb-64`;
const Wrapper = tw.div`lg:flex`;

const BlogPage = ({ pageContext, data }) => {
    const {
        locale, seo,
    } = pageContext;
    const posts = data.posts.edges;
    return (
        <Layout
            seo={seo}
            locale={locale}
        >
            <Header />
            <Container>
                <Wrapper css={{ minHeight: '62vh' }}>
                    <ContentWrapper>
                        <div tw="divide-y divide-gray-200">
                            <div tw="pt-6 pb-8 space-y-2 md:space-y-5">
                                <h1 tw="text-3xl leading-9 font-extrabold text-gray-900 tracking-tight sm:text-4xl sm:leading-10 md:text-6xl">
                                    <Trans>Latest</Trans>
                                </h1>
                                <p tw="text-lg leading-7 text-gray-500">
                                    <Trans>Our latest blog posts.</Trans>
                                </p>
                            </div>

                            <ul tw="divide-y divide-gray-200">
                                {posts.map(({ node: { frontmatter } }) => (
                                    <li key={frontmatter.slug} tw="py-12">
                                        <article tw="space-y-2 xl:grid xl:grid-cols-4 xl:space-y-0 xl:items-baseline">
                                            <dl>
                                                <dt tw="sr-only">Published on</dt>
                                                <dd tw="text-base leading-6 font-medium text-gray-500">
                                                    <time dateTime={frontmatter.date}>{frontmatter.date}</time>
                                                </dd>
                                            </dl>
                                            <div tw="space-y-5 xl:col-span-3">
                                                <div tw="space-y-6">
                                                    <h2 tw="text-2xl leading-8 font-bold tracking-tight">
                                                        <Link
                                                            to={getBlogPage(frontmatter.slug)}
                                                            tw="text-gray-900"
                                                        >
                                                            {frontmatter.title}
                                                        </Link>
                                                    </h2>
                                                    {frontmatter.excerpt && (
                                                        <div tw="max-w-none text-gray-500">
                                                            {frontmatter.excerpt}
                                                        </div>
                                                    )}
                                                </div>
                                                <div tw="text-base leading-6 font-medium">
                                                    <Link
                                                        to={getBlogPage(frontmatter.slug)}
                                                        tw="text-purple-500 hover:text-purple-600"
                                                        aria-label={`Read "${frontmatter.title}"`}
                                                    >
                                                        <Trans>Read more</Trans>
                                                        {' '}
                                                        &rarr;
                                                    </Link>
                                                </div>
                                            </div>
                                        </article>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </ContentWrapper>
                </Wrapper>
            </Container>
            <Footer />
        </Layout>
    );
};

export default BlogPage;

export const pageQuery = graphql`
    query BlogPageQuery($locale: String!) {
        posts: allMdx(
            filter: {frontmatter: {type: {eq: "blog"},
            locale: {eq: $locale}}},
            sort: {order: DESC, fields: [frontmatter___datePublished]}
        ) {
            edges {
                node {
                    frontmatter {
                        slug
                        date(formatString: "MMMM DD, YYYY")
                        title
                        locale
                    }
                }
            }
        }
    }
`;
